.sectionImage {
  text-align: center !important;
  img {
    @media (max-width: 479px) {
      padding-top: 3rem;
    }
    height: 8rem;
  }
}

.sectionText {
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 3rem;

  a {
    color: #3e8682;
    font-weight: 600;
    text-decoration: none;
  }
}
