/* Sections Base Layout */
.section {
  min-height: 400px;

  .row{
    min-height: 400px;
  }

  h1 {
    font-size: 1.8rem;
    font-weight: 500;
    font-style: normal;
    
    padding-top: 3rem;
    padding-bottom: 0.6rem;

    text-transform: uppercase;
  }

  h2 {
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 1.9rem;
    font-style: normal;

    padding-bottom: 1rem;
  }

  h3 {
    font-size: 1.1rem;
    font-weight: 400;
    font-style: normal;
  }

  p{
    font-size: 1.1rem;
  }
}
