.button{
    color: #3E8682 !important;
    
    border: none; 
    background: none important; 
    border-color: #3E8682;
    padding: 0;

    text-transform: uppercase;

    &:hover{
        border-color: #F0F7F7;
        background-color: #F0F7F7;
    }

    img{
        padding-right: 1rem;
    }
}