.form {
  label {
    font-size: 1rem;
    img {
      padding-left: 0.2rem;
      height: 1.3rem;
    }
  }

  input,
  textarea {
    margin-bottom: 1rem;
    margin-right: 0.5rem;
  }

  textarea {
    height: 8rem !important;
  }
}

.alert {
  margin-top: 1rem;
  p {
    font-size: 1rem;
    margin: 0;
  }

  img {
    height: 2rem;
    margin-right: 2rem;
  }
}

.contact {
  padding-bottom: 2rem;
}

.sendButton {
  padding-bottom: 2rem;
}

.tooltip {
  position: relative;
  display: inline-block;

  .tooltipText {
    font-size: 0.8rem;
    visibility: hidden;
    background-color: #F0F7F7;
    color: #3A4141;
    padding: 1rem;
    border-radius: 2px;
    position: absolute;
    z-index: 1;

    width: 400px;
    top: -3rem;
    left: 140%;

    @media (max-width: 479px) {
      width: 200px !important;
      top: -10rem !important;
      left: 100% !important;
    }
  }

  &:hover .tooltipText {
    visibility: visible;
  }
}
