.sectionRow {
  background-color: #f0f7f7;
  min-height: 400px;
}

.sectionCol {
  height: 9rem;
  width: 9rem;

  @media (min-width: 768px) {
    height: 14rem;
    width: 14rem;
  }
}
