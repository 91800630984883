.titleRow {
  padding-top: 6rem;
  height: 30rem;
  background-color: #d7e0e5;

  h1 {
    padding-left: 6rem;
    @media (max-width: 479px) {
      padding-left: 2rem !important;
    }
    font-size: 2.4rem;
    font-weight: 100;
  }

  .titleImage {
    @media (max-width: 479px) {
      text-align: center !important;
    }

    img {
      height: 160px;
      @media (max-width: 479px) {
        -left: 2rem !important;
      }
    }
  }
}
