/* 
#F0F7F7 // Hellgrau 
#D7E0E5 // Grau
#F4F4F4 // Grau2 (Wie ich arbeite)
#ACDBC5 // Minz
#3E8682 // Grün
#3A4141 // Kohle
#F184D3 // Pinky
#FCD6B3 // Holz
#C68A6D // Lehm
*/

/* DM Sans regular */

@font-face {
    font-family: "DMSans";
    src: url("./assets/fonts/DMSans-Regular.eot");
    src: url("./assets/fonts/DMSans-Regular.eot?#iefix") format('embedded-opentype'),
         url("./assets/fonts/DMSans-Regular.woff2") format('woff2'),
         url("./assets/fonts/DMSans-Regular.woff") format('woff'),
         url("./assets/fonts/DMSans-Regular.ttf") format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "DMSans";
    src: url("./assets/fonts/DMSans-Medium.eot");
    src: url("./assets/fonts/DMSans-Medium.eot?#iefix") format('embedded-opentype'),
         url("./assets/fonts/DMSans-Medium.woff2") format('woff2'),
         url("./assets/fonts/DMSans-Medium.woff") format('woff'),
         url("./assets/fonts/DMSans-Medium.ttf") format('truetype');
    font-weight: 500;
    font-style: normal;
}


/* DM Sans italic */
@font-face {
    font-family: "DMSans";
    src: url('./assets/fonts/DMSans-Italic.eot');
    src: url('./assets/fonts/DMSans-Italic.eot?#iefix') format('embedded-opentype'),
         url('./assets/fonts/DMSans-Italic.woff2') format('woff2'),
         url('./assets/fonts/DMSans-Italic.woff') format('woff'),
         url('./assets/fonts/DMSans-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "DMSans";
    src: url('./assets/fonts/DMSans-Bold.eot');
    src: url('./assets/fonts/DMSans-Bold.eot?#iefix') format('embedded-opentype'),
         url('./assets/fonts/DMSans-Bold.woff2') format('woff2'),
         url('./assets/fonts/DMSans-Bold.woff') format('woff'),
         url('./assets/fonts/DMSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}



body {
  margin: 0;
  font-family: 'DMSans', sans-serif;
  font-size: 1.2rem;
  color: #3A4141;
}

.btn {
    line-height: 1.4rem;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
}