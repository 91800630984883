.sectionImage {
  margin-top: auto;
  padding: 0;
}

.sectionText {
  background-color: #ffffff;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 3rem;

  button{
      margin-top: 2rem;
  }
}
