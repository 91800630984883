.button{
    color: #3E8682 !important;
    border-color: #3E8682;

    text-transform: uppercase;
    
    &:hover{
        color: #F0F7F7 !important;
        border-color: #3E8682;
        background-color: #3E8682;
    }
}