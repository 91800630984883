.section {
  background-color: #f4f4f4;
}

.sectionText {
  padding-top: 4rem;
  padding-bottom: 3rem;

  p {
    font-size: 1.2rem;
  }

  p.citation {
    text-align: center;
    padding: 1.5rem;

    font-style: italic;
    font-size: 1.4rem;

    background-color: #f0f7f7;
    color: #3e8682;
  }

  button {
    margin-top: 3rem;
  }
}
