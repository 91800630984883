.footerRowMemberships {
  background-color: #d7e0e5;

  padding-left: 2rem;
  padding-top: 2rem;

  min-height: 80px;

  img{
      margin-right: 2rem;
      margin-bottom: 1rem;
      height: 60px;
    }

  // .logos {
  //   display: flex;
  //   justify-content: left;

  //   height: 60px;

  //   img{
  //     padding-right: 2rem;
  //     height: 60px;
  //   }

  //   .xingLogo {
  //     img {
  //       height: 40px;
  //     }
  //   }
  // }
}

.footerRow {
  height: 4rem;
  background-color: #d7e0e5;

  font-size: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;

  .logos {
    display: flex;
    justify-content: space-between;
  }

  a {
    color: #3a4141 !important;

    &:hover,
    &:visited,
    &:active {
      color: #3a4141 !important;
    }
  }
}
