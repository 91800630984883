.offerAreaLink {
  text-decoration: none;
  color: #3a4141;

  :hover {
    text-decoration: none;
    color: #3a4141;
  }
}
.offerArea {
  height: 9rem;
  width: 9rem;

  @media (min-width: 768px) {
    height: 14rem;
    width: 14rem;
  }

  margin: 0 auto;
  text-align: center;
  display: flex;

  &:hover {
    background-color: #ffffff;
  }

  .offerAreaItems {
    margin: auto;
    margin-top: 22%;

    img {
      height: 40px;

      @media (min-width: 768px) {
        height: 50px;
      }
    }

    p {
      padding-top: 1rem;
      font-size: 1rem;

      @media (min-width: 768px) {
        padding-top: 1rem;
        max-width: 8rem;
        font-size: 1.2rem;
      }
    }
  }
}
