.section4 {
  background-color: #f4f4f4;
}

.sectionText {
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 3rem;

  h1 {
    padding-bottom: 0.6rem;
  }
  h2 {
      padding-top: 1.4rem;
  }
  h3{
    font-size: 1.2rem;
  }
  p {
    font-size: 1.0rem;
  }
}

