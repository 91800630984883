.sectionText {
  padding-left: 3rem;
  padding-right: 3rem;
  background-color: #acdbc5;

  padding-bottom: 6rem;

  a{
      margin-top: 2rem;
  }
}

.sectionImage {
  background-color: #d7e0e5;
  margin-top: auto;
}
