.logoIcon {
  height: 1.6rem;
  padding-right: 2rem;
  margin-top: 1rem;
}

.logoText{
  font-size: 1.7rem;
  font-weight: 500;
  margin-top: 1rem;
}

.sidebarContainer {
  position: fixed;
  top: 0;
  left: auto;
  right: 0;

  width: 100%;
  z-index: 10;

  .sidebarMenu {
    position: relative;
    display: none;
  }

  .sidebarMenuVisible {
    display: block;
  }

  .navbarMenuItemContainer {
    position: absolute;
    right: 0;
    top: 0;

    .navbarMenuItems {
      width: 25rem;
      padding: 3rem;
      padding-top: 6rem;
      background-color: #3e8682;
      height: 100vh;
      flex-wrap: nowrap !important;

      .navItem {
        display: block;
        padding: 0.5rem 1rem;

        a {
          text-decoration: none !important;
          font-size: 1.6rem;

          padding-bottom: 1.4rem;
          color: #f0f7f7;

          &:hover {
            color: #fcd6b3;
          }

          &::selection {
            background-color: #3e8682;
          }

          &.selected {
            color: #fcd6b3 !important;
          }
        }
      }

      .navbarMenuItemsSub {
        padding-left: 1.5rem;

        .navItemSub {
          list-style: none;
          padding-top: 0.5rem;

          a {
            font-size: 1.4rem;

            &.selected {
              color: #fcd6b3 !important;
            }
          }
        }
      }
    }
  }
}

.navbarTogglerIcon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  z-index: 500000;

  &.navbarOpen {
    background-image: url("../../assets/icons/menuClose.svg");
  }

  &.navbarClosed {
    background-image: url("../../assets/icons/menu.svg");
  }
}
