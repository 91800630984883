.teaserXS {
  @media (min-width: 768px) {
    display: none;
  }

  background-color: #d7e0e5;
  display: block;
  position: relative;

  .teaserText {
    position: absolute;
    bottom: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;

    z-index: 2;

    h1 span {
      background-color: #FCD6B3;
      text-transform: none !important;
    }
  }

  .teaserImage {
    z-index: 1;
  }
}

.teaser {
  @media (max-width: 767px) {
    display: none;
  }

  display: block; 

  .teaserLeft {
    margin-top: 10rem;
    padding-left: 1.5rem;

    h1 {
      font-size: 2rem;
      margin-bottom: 2rem;
      text-transform: none !important;
    }
  }

  .teaserRight {
    background-color: #d7e0e5;
    height: 100%;
  }
}
