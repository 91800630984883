.sectionContent {

  padding-bottom: 3rem;
  
  .contactInfo {
    p {
      margin-bottom: 0.2rem;
      font-size: 1.1rem;
    }

    a{
      text-decoration: none !important;
      color: #3A4141;
    }

    margin-bottom: 2rem;
  }
}
